import React from 'react'
import './raters-cond.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
const RatersConditions = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckBoxChange = (event) => {
        const isChecked = event.target.checked;
        setIsChecked(isChecked);
       
  
        // Log the value to the console
        console.log(isChecked);
      };
      const preventLink = (event) => {
        if (!isChecked) {
          event.preventDefault();
          toast.error("يجب الموافقة على الشروط والأحكام");
        }
      }
  return (
    
<div className="raters-cond">
    <div className="container">
        <h1>معلومات من سيقومون بتقييمك</h1>
        <div>هذا المقياس يعتمد على نظرية 360 درجة والتي تعني أن تطبيق المقياس لا يتم فقط من قبل الطالب وإنما أيضاً من قبل من حوله ممن يتعامل معهم بشكل متكرر. ونتيجة لذلك سيتجمع لدينا رأي مجموعة جيدة ممن يتعامل معهم الطالب، وبعدها يتم تحليل هذا المقياس واستخراج التقرير.

لذا من المهم أن تقوم الآن بكتابة معلومات هذه المجموعة التي ستعمل على تقييمك أنت، وعليك إخبارهم بأن إدارة البرنامج ستُرسل إليهم رابط المقياس ليقوموا بتقييمك، ولإتمام هذه الخطوة فإننا بحاجة إلى معرفة الاسم والبريد الإلكتروني ورقم الجوال لكل شخص مقترح من طرفك.

والفئات التي نهتم بأن تتواصل معها وتطلب منهم تقييمك هم على ثلاثة فئات كالتالي:
</div>
<div className='soso'> من هم في حكم مشرفيك، بمعنى أنه يتوجب عليك الرجوع إليهم وأخذ الصلاحيات منهم، وهم يقومون بدور إشرافي مباشر عليك  <span> -</span></div>
<div>من هم في حكم الزملاء، بمعنى أنهم زملاؤك في المدرسة ويتعاملون معك كثيرا. - </div>
<div>آخرون، ربما من خارج دائرة مشرفيك وزملائك، ولكن لابد من أن يكون بينك وبينهم تعامل مباشر متكرر، مثلاً الأقارب والإخوان -</div>
<div>عليك قبل إرسال طلب التقييم أن تتواصل معهم بشكل مباشر وتشرح لهم فكرة المقياس، وبأن عليهم تقييمك بشكل حيادي صادق بلا مجاملة أو تجني، وبين لهم بأن معلوماتهم وما سيكتبونه عنك سيكون مخفيا عنك، ولن تستطيع التعرف على التقييمات الشخصية ومصدرها.</div>
<div>عليك قبل إرسال طلب التقييم أن تتواصل معهم بشكل مباشر وتشرح لهم فكرة المقياس، وبأن عليهم تقييمك بشكل حيادي صادق بلا مجاملة أو تجني، وبين لهم بأن معلوماتهم وما سيكتبونه عنك سيكون مخفيا عنك، ولن تستطيع التعرف على التقييمات الشخصية ومصدرها.</div>
<div>ينبغي أن لا يقل عدد المقيمين عن 3 أشخاص في مجموعهم موزعين على المستويات المذكورة أعلاه ولا يزيدون على 5 أشخاص.</div>
<label htmlFor="">
    نعم فهمت
    <input type="checkbox" checked={isChecked} 
    onChange={handleCheckBoxChange}/>
</label>
    <Link onClick={preventLink} to="/raters">التالي</Link>
    </div>
</div>
  )
}

export default RatersConditions